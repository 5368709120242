<template>
  <div class="component story story--has-columns">
    <div class="story__line">
      <div class="story__line-overlay" ref="line" :style="lineStyle"></div>
    </div>

    <div class="component__container">
      <div class="component__row" ref="container">
        <div class="story__wrapper">
          <div class="story-item" ref="intro">
            <h2>
              {{ $t('story.headline') }}
            </h2>
          </div>
          <story-item
            v-for="(story, index) in stories"
            ref="stories"
            :key="index"
            :icon="story.icon"
            :headline="story.headline"
            :copy="story.copy"
            :isVisible="positions[index] ? positions[index].visible : false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Position from '../utils/position';
import StoryItem from './StoryItem.vue';

import Breakpoints from '../utils/breakpoints';

export default {
  name: 'Story',

  components: {
    StoryItem,
  },

  data() {
    return {
      breakpoint: '',
      onWindowScroll: null,
      positions: [],
      line: {
        top: 0,
        moveFrom: 0,
        offset: 0,
        maxOffset: 0,
      },
      finished: false,
    };
  },

  mounted() {
    // Inject current breakpoint
    this.breakpoint = Breakpoints.currentBreakpoint;
    // Register breakpoint handler to enebale or disable behaviour on breakpoint change
    Breakpoints.registerHandler(this.onBreakpointChange.bind(this));
    // Do nothing when mobile view
    if (!Breakpoints.isAbove('xs')) {
      return;
    }

    this.enableScrollBehaviour();
  },

  computed: {
    stories() {
      return this.$t('story.stories').map((story) => ({
        ...story,
      }));
    },
    lineStyle() {
      return `transform: translateY(${this.line.offset}px)`;
    },
  },

  methods: {
    enableScrollBehaviour() {
      this.setStoryPositions();
      this.getScrollOffsets();
      this.onWindowScroll = this.createScrollHandler();
      this.addScrollListener();
    },

    disableScrollBehaviour() {
      this.positions = [];
      this.$refs.stories.forEach((story) => story.$el.removeAttribute('style'));
      this.removeScrollListener();
    },

    onBreakpointChange(breakpoint) {
      switch (breakpoint.key) {
        case 'xs':
          this.$nextTick(() => {
            this.disableScrollBehaviour.bind(this);
          });
          break;
        case 'md':
          this.$nextTick(this.disableScrollBehaviour.bind(this));
          this.$nextTick(this.enableScrollBehaviour.bind(this));
          break;
        case 'lg':
          this.$nextTick(this.disableScrollBehaviour.bind(this));
          this.$nextTick(this.enableScrollBehaviour.bind(this));
          break;
        default:
          break;
      }
    },

    createScrollHandler() {
      return function onWindowScroll() {
        this.setStoryVisibilities();
        this.setLinePostion();
        // remove scroll handling when maximum offset is reached
        if (this.line.offset === this.line.maxOffset && this.line.maxOffset) {
          this.removeScrollListener();
        }
      }.bind(this);
    },

    addScrollListener() {
      window.addEventListener('scroll', this.onWindowScroll);
    },

    removeScrollListener() {
      window.removeEventListener('scroll', this.onWindowScroll);
      this.onWindowScroll = null;
    },

    createStoryPositions() {
      this.$refs.stories.forEach((story) => {
        const top = Position.top(story.$el);
        this.positions.push({
          top,
          showAt: top - window.innerHeight + (window.innerHeight / 4),
          visible: false,
        });
      });
    },

    getScrollOffsets() {
      // Get line offsets
      const lineTop = Position.top(this.$refs.line);
      this.line.top = lineTop;
      this.line.moveFrom = lineTop - window.innerHeight + (window.innerHeight / 4);
      this.line.maxOffset = this.$refs.container.clientHeight;
      // Get story offsets
      this.$refs.stories.forEach((story) => {
        const top = Position.top(story.$el);
        this.positions.push({
          top,
          showAt: top - window.innerHeight + (window.innerHeight / 4),
          visible: false,
        });
      });
    },

    setStoryPositions() {
      const stories = [this.$refs.intro, ...this.$refs.stories];
      const containerTop = Position.top(this.$refs.container);
      const lastBottomPosInColumns = [containerTop, containerTop];

      stories.forEach((story, index) => {
        const col = index % 2;
        const lastBottomPosInColumn = lastBottomPosInColumns[col];
        const el = story.$el ? story.$el : story;
        const top = Position.top(el);
        const margin = top - lastBottomPosInColumn;
        el.style.marginTop = `${-margin}px`;
        // Add last pos in current column
        lastBottomPosInColumns[col] = top + el.clientHeight - margin;
      });
    },

    setStoryVisibilities() {
      this.positions.forEach((position) => {
        /* eslint-disable no-param-reassign */
        position.visible = position.showAt < window.scrollY || position.visible;
        /* eslint-enable no-param-reassign */
      });
    },

    setLinePostion() {
      this.line.offset = this.line.moveFrom < window.scrollY
        ? this.line.offset = Math.min(window.scrollY - this.line.moveFrom, this.line.maxOffset)
        : 0;
    },
  },
};
</script>
