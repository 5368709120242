import iconStory1 from '../assets/icon_story_1.svg';
import iconStory2 from '../assets/icon_story_2.svg';
import iconStory3 from '../assets/icon_story_3.svg';

import testimonal7 from '../assets/testimonials/mutter.png';
import testimonal7Md from '../assets/testimonials/mutter_md.png';
import testimonal7Lg from '../assets/testimonials/mutter_lg.png';
import testimonal6 from '../assets/testimonials/oma.png';
import testimonal6Md from '../assets/testimonials/oma_md.png';
import testimonal6Lg from '../assets/testimonials/oma_lg.png';
import testimonal5 from '../assets/testimonials/tochter.png';
import testimonal5Md from '../assets/testimonials/tochter_md.png';
import testimonal5Lg from '../assets/testimonials/tochter_lg.png';
import testimonal4 from '../assets/testimonials/vater.png';
import testimonal4Md from '../assets/testimonials/vater_md.png';
import testimonal4Lg from '../assets/testimonials/vater_lg.png';
import testimonal2 from '../assets/testimonials/sohn.png';
import testimonal2Md from '../assets/testimonials/sohn_md.png';
import testimonal2Lg from '../assets/testimonials/sohn_lg.png';
import testimonal1 from '../assets/testimonials/opa.png';
import testimonal1Md from '../assets/testimonials/opa_md.png';
import testimonal1Lg from '../assets/testimonials/opa_lg.png';

import hero from '../assets/hero/hero.png';
import heroMd from '../assets/hero/hero_md.png';
import heroLg from '../assets/hero/hero_lg.png';

import backgroundImage from '../assets/produkt-module/product_background.jpg';
import videoImage from '../assets/produkt-module/video_placeholder.jpg';
import productImage from '../assets/produkt-module/product.png';
import topImage from '../assets/produkt-module/container_top.png';
import bottomImage from '../assets/produkt-module/container_bottom.png';

import productStory1VideoImage from '../assets/product-stories/1/produkt-highligh_video-placeholder.png';
import productStory1ProductImage from '../assets/product-stories/1/BJE-axcent_crystal_slim_koralle_weiss_matt.png';
import productStory2VideoImage from '../assets/product-stories/2/video_placeholder.jpg';
import productStory2ProductImage from '../assets/product-stories/2/product.png';
import productStory3ProductImage from '../assets/product-stories/3/product.png';
import productStory3VideoImage from '../assets/product-stories/3/video_placeholder.jpg';
import productStory4ProductImage from '../assets/product-stories/4/product.png';
import productStory4VideoImage from '../assets/product-stories/4/video_placeholder.jpg';
import productStory5ProductImage from '../assets/product-stories/5/product.png';
import productStory5VideoImage from '../assets/product-stories/5/video_placeholder.jpg';
import productStory6ProductImage from '../assets/product-stories/6/product.png';
import productStory6VideoImage from '../assets/product-stories/6/video_placeholder.jpg';
import productStory7ProductImage from '../assets/product-stories/7/product.png';
import productStory7VideoImage from '../assets/product-stories/7/video_placeholder.jpg';
import productStory8ProductImage from '../assets/product-stories/8/product.png';
import productStory8VideoImage from '../assets/product-stories/8/video_placeholder.jpg';
import productStory9ProductImage from '../assets/product-stories/9/product.png';
import productStory9VideoImage from '../assets/product-stories/9/video_placeholder.jpg';
import productStory10ProductImage from '../assets/product-stories/10/product.png';
import productStory10VideoImage from '../assets/product-stories/10/video_placeholder.jpg';

export default {
  de: {
    hero: {
      header: {
        subtitle: 'Lebensqualität für dein Zuhause',
        title: 'Wenn du freie Bahn brauchst_',
        image: {
          src: hero,
          variants: [heroLg, heroMd],
        },
      },
      intro: {
        heading: {
          lineOne: 'Von <span class="hero__intro-text--focused">Frühaufstehern</span>,',
          lineTwo: '<span class="hero__intro-text--focused">Nachteulen</span> und <span class="hero__intro-text--focused">Stilikonen</span>:',
          lineThree: 'Wenn sich die <span class="hero__intro-text--focused">ganze</span>',
          lineFour: '<span class="hero__intro-text--focused">Familie zuhause</span> fühlt.',
        },
        paragraph: 'Vom Wettbewerb um die freie Steckdose bis zur Lovestory im Dämmerlicht: Unsere Mini-Serie zeigt unterhaltsame Episoden aus dem Lebensalltag einer Familie. Und sie beschreibt, wie kluge Technik es möglich macht, dass sich alle unter einem Dach wohlfühlen.',
      },
    },
    testimonials: [
      {
        image: {
          src: testimonal7,
          variants: [testimonal7Lg, testimonal7Md],
        },
        quote: 'Mein Mantra: Es gibt immer eine Lösung.',
        author: 'Mutter Luise, ständig unter Strom',
      },
      {
        image: {
          src: testimonal5,
          variants: [testimonal5Lg, testimonal5Md],
        },
        quote: 'Meine Wirkung auf andere? Sehr flashig.',
        author: 'Tochter Trixi, Queen of Style',
      },
      {
        image: {
          src: testimonal6,
          variants: [testimonal6Lg, testimonal6Md],
        },
        quote: 'Ich brauch meine Freiheit, und die gibt´s durch Sicherheit.',
        author: 'Oma Alma, Studentin der Kräuterkunde',
      },
      {
        image: {
          src: testimonal4,
          variants: [testimonal4Lg, testimonal4Md],
        },
        quote: 'Begegnungen der 3. Art? Bitte nicht zuhause. Ich will wissen, wer kommt und geht.',
        author: 'Alex, Vater und Controller ',
      },
      {
        image: {
          src: testimonal2,
          variants: [testimonal2Lg, testimonal2Md],
        },
        quote: 'Meine Schwester und ich versteh\'n uns super. Auch wenn ich manchmal durchgeknallt bin – sie bleibt entspannt.',
        author: 'Max, der Unbekümmerte',
      },
      {
        image: {
          src: testimonal1,
          variants: [testimonal1Lg, testimonal1Md],
        },
        quote: 'Über Geschmack lässt sich streiten. Über Stil nicht. Ich mag‘s knallig-korallig.',
        author: 'Opa Rainer, Individualist',
      },
    ],
    story: {
      headline: 'Qualität Made in Germany',
      stories: [
        {
          icon: iconStory1,
          headline: 'Individuelle Lösungen',
          copy: 'Von der Steckdose bis zur Gebäudeautomation: Du kannst aus über 6.000 Elektroinstallationsprodukten in unterschiedlichsten Farben, Formen und Materialien wählen.',
        },
        {
          icon: iconStory2,
          headline: 'Exzellenter Service',
          copy: 'Wir sind dein Projektpartner und begleiten dich mit deinem Elektroinstallateur in allen Phasen: Von der Idee über die Planung bis zur Umsetzung. Auch danach sind wir für dich da, wenn du Unterstützung brauchst.',
        },
        {
          icon: iconStory3,
          headline: 'Darum Busch-Jaeger',
          copy: 'Von der Zeitsteuerung für Jalousien bis zum vernetzten Brandmelder: Mit den Produkten und Lösungen von Busch-Jaeger steigerst du nicht nur deinen Wohnkomfort. Du sorgst auch für mehr Sicherheit und Energieeffizienz in deinem Zuhause.',
        },
      ],
    },
    productModule: {
      videoImage,
      backgroundImage,
      topImage,
      bottomImage,
      image: productImage,
      imageVariants: [],
      video: 'https://www.youtube.com/embed/Bghq0EFW36w',
      videoHeading: '',
      accentHeading: 'Steckdose auslösen ',
      productButtonLink: 'https://www.busch-jaeger.de/produkt/busch-balance-si/busch-servicesteckdose',
      slides: [
        {
          heading: 'Wenn du freie Bahn brauchst ',
          subheading: 'Busch-Servicesteckdose®',
          text: 'Mal wieder voll beladen? Immer schön handlungsfähig bleiben mit der Busch-Servicesteckdose®. Nur kurz den Drehhebel drücken und schon kannst Du den Stecker ganz leicht herausziehen.',
        },
        {
          heading: 'Praktisch im Haushalt',
          text: 'Mit einem leichten Druck auf den Drehhebel lassen sich festsitzende Winkelstecker – auch mit feuchten Händen und sogar per Fuß – ohne Kraftaufwand herausziehen. Besonders praktisch für Haushaltsgeräte wie Wasserkocher, Toaster oder Mixer.',
        },
        {
          heading: 'Perfekt in jeder Lebenslage!',
          text: 'Auch Du wirst älter! Da weiß man Hilfe im Haushalt zu schätzen. Die Busch-Servicesteckdose® ist von der Deutschen Gesellschaft für Gerontotechnik® geprüft und besonders für Senioren bestens geeignet.',
        },
      ],
    },
    productStory: {
      title: 'Was bisher geschah',
      text: 'Hier findest du die bisherigen Erlebnisse der Busch-Jaeger Familie. Jede Woche kommt eine neue Episode hinzu.',
      products: [
        {
          title: 'Wenn´s genau Dein Style sein soll.',
          subtitle: 'Impuls',
          text: 'Ein Schalter als Eyecatcher – mit fast hypnotischer Wirkung in leuchtendem Weiß, Rot oder Blau.',
          publishDate: '12-29-2021',
          imgVideoPreviewSrc: productStory10VideoImage,
          video: 'https://www.youtube.com/embed/fjsCol2sYUs',
          productImgSrc: productStory10ProductImage,
          detailsText: '<p>Adieu Einheitslook! Kultivier zuhause Deinen eigenen Style. Der unkonventionelle Designschalter Impuls flasht jeden Raum mit seinem Look und der Lichtaura, denn er leuchtet im Dunklen ganz dezent in Weiß, Rot oder Blau.</p><p><ul><li>Design mit Lichtaura</li><li>Permanentes Nachtdesign</li><li>Hoher Bedienkomfort</li></ul></p>',
          productLink: 'https://www.busch-jaeger.de/produkte/schalterdesign/impuls',
          findInstallerLink: 'https://www.busch-jaeger.de/installateur-finden',
        },
        {
          title: 'Wenn man rechtzeitig gewarnt wird.',
          subtitle: 'Busch-Rauchalarm®',
          text: 'Besonders hochwertige Rauchmelder für höchste Sicherheit im ganzen Haus.',
          publishDate: '12-15-2021',
          imgVideoPreviewSrc: productStory9VideoImage,
          video: 'https://www.youtube.com/embed/2okUgS8rKnI',
          productImgSrc: productStory9ProductImage,
          detailsText: '<p>Wir haben Dich gewarnt! Der Sicherheitsstandard von Busch-Rauchalarm® setzt echte Maßstäbe. Dank ausgezeichneter Qualität und Langlebigkeit Schutz im ganzen Haus, einfach zu installieren und easy zu vernetzen. </p><p><ul><li>Zuverlässiger Alarm</li><li>Garantiert unüberhörbar</li><li>Sicherheit im ganzen Haus</li></ul></p>',
          productLink: 'https://www.busch-jaeger.de/produkt/busch-rauchalarm',
          findInstallerLink: 'https://www.busch-jaeger.de/installateur-finden',
        },
        {
          title: 'Wenn im Handumdrehen die Stimmung steigt.',
          subtitle: 'Busch-Drehdimmer',
          text: 'Mit einem Dreh die Beleuchtung dimmen.',
          publishDate: '12-15-2021',
          imgVideoPreviewSrc: productStory8VideoImage,
          video: 'https://www.youtube.com/embed/lW9AoB8vU1k',
          productImgSrc: productStory8ProductImage,
          detailsText: '<p>Mit einem Dreh das Licht steuern, so wie es zur Stimmung passt? Der klassische Busch-Drehdimmer ist beleuchtbar, einfach nachzurüsten und kann in bestehende Schaltungen integriert werden. Erhältlich in elf Busch-Jaeger Produktfamilien.</p><p><ul><li>Per Hand einfach dimmen</li><li>Intuitive Bedienung</li><li>Individuell nachrüstbar</li></ul></p></p>',
          productLink: 'https://www.busch-jaeger.de/produkt/busch-drehdimmer',
          findInstallerLink: 'https://www.busch-jaeger.de/installateur-finden',
        },
        {
          title: 'Wenn Du im Leben keine Kompromisse machen willst.',
          subtitle: 'Busch-dynasty®',
          text: 'Handgefertigte Messing-Schalter in ausgesuchter Qualität und luxuriöser Ästhetik. Jeder Schalter ein Unikat.',
          publishDate: '12-08-2021',
          imgVideoPreviewSrc: productStory7VideoImage,
          video: 'https://www.youtube.com/embed/LgE7mJX15l4',
          productImgSrc: productStory7ProductImage,
          detailsText: '<p>Feiere die Einzigartigkeit. Denn keiner der Lichtschalter der Busch-dynasty® Serie ist wie der andere. </p><p><ul><li>Unikate aus Messing</li><li>Einzigartige Qualität</li><li>Imposante Gestaltungsmöglichkeiten</li></ul></p></p>',
          productLink: 'https://www.busch-jaeger.de/produkte/schalterdesign/busch-dynasty',
          findInstallerLink: 'https://www.busch-jaeger.de/installateur-finden',
        },
        {
          title: 'Wenn Du mehr Platz für Dich brauchst.',
          subtitle: 'SCHUKO® USB-Steckdose',
          text: 'Die SCHUKO® USB-Steckdose ist Dein fester Platz zum Aufladen.',
          publishDate: '11-24-2021',
          imgVideoPreviewSrc: productStory6VideoImage,
          video: 'https://www.youtube.com/embed/_1WvEEvb3vI',
          productImgSrc: productStory6ProductImage,
          detailsText: '<p>Dein fester Platz zum Aufladen für Smartphone & Co. mit zwei praktischen USB-Anschlüsse und niedrigem Energieverbrauch inklusive.</p><p><ul><li>Fester Platz zum Aufladen</li><li>Mehrfache Lademöglichkeiten parallel</li><li>Berührungsschutz für mehr Sicherheit</li></ul></p>',
          productLink: 'https://www.busch-jaeger.de/produkt/schuko-usb-steckdose',
          findInstallerLink: 'https://www.busch-jaeger.de/installateur-finden',
        },
        {
          title: 'Wenn plötzlich die Nacht zum Tag wird.  ',
          subtitle: 'Busch-Wächter® Bewegungsmelder ',
          text: 'Automatische Lichtschaltung und die passende Überwachung für innen und außen.',
          publishDate: '11-17-2021',
          imgVideoPreviewSrc: productStory5VideoImage,
          video: 'https://www.youtube.com/embed/omyn-Z8l-g8',
          productImgSrc: productStory5ProductImage,
          detailsText: '<p>Alles unter Kontrolle. Der passende Bewegungsmelder bringt automatisch einfach alles ans Licht. So sorgt er für Sicherheit rund um die Uhr. Für jeden Einsatzzweck die passende Lösung, auch in puncto Design und Energie-Effizienz. </p><p><ul><li>Sicherheit rund um die Uhr</li><li>Automatische Lichtsteuerung</li><li>Individueller Komfort plus Design</li></ul></p>',
          productLink: 'https://www.busch-jaeger.de/produkt/future-linear/busch-waechter-180-flex',
          findInstallerLink: 'https://www.busch-jaeger.de/installateur-finden',
        },
        {
          title: 'Wenn alles perfekt getimed ist.',
          subtitle: 'Busch-Compact-Timer',
          text: 'Intelligente Zeitsteuerung für Jalousien, Rollläden und Markisen.',
          publishDate: '11-10-2021',
          imgVideoPreviewSrc: productStory4VideoImage,
          video: 'https://www.youtube.com/embed/k_QTgo-szOU',
          productImgSrc: productStory4ProductImage,
          detailsText: '<p>Ob Mittagsschlaf oder Urlaubsreise: Das zeitgesteuerte Jalousiemanagement verbindet Wohnkomfort, Sicherheit und Effizienz.</p><p><ul><li>Minutengenaue Steuerung</li><li>Automatische Sommer-/Winterzeit-Umstellung</li><li>Intuitive Handhabung</li></ul></p>',
          productLink: 'https://www.busch-jaeger.de/produkt/busch-compact-timer',
          findInstallerLink: 'https://www.busch-jaeger.de/installateur-finden',
        },
        {
          title: 'Wenn man die gleiche Wellenlänge hat.',
          subtitle: 'Busch-Radio DAB +',
          text: 'Das Busch-DigitalRadio schafft in jedem Raum die passende Atmosphäre.',
          publishDate: '11-03-2021',
          imgVideoPreviewSrc: productStory3VideoImage,
          video: 'https://www.youtube.com/embed/v-oDlQUahNA',
          productImgSrc: productStory3ProductImage,
          detailsText: '<p>Musikgenuss statt Hintergrundrauschen: Das Busch-DigitalRadio sorgt für den perfekten Klanggenuss in Bad, Küche oder Wohnzimmer.</p><p><ul><li>Dezentes Design</li><li>Hochauflösendes Farbdisplay</li><li>Senderauswahl ohne Frequenzsuche</li></ul></p>',
          productLink: 'https://www.busch-jaeger.de/produkt/future-linear/busch-radio-dab',
          findInstallerLink: 'https://www.busch-jaeger.de/installateur-finden',
        },
        {
          title: 'Wenn sich Überspannung besser kontrollieren lässt.',
          subtitle: 'Busch-Protector®',
          text: 'Der Busch-Protector® schützt elektronische Geräte vor Überspannungsschäden. ',
          publishDate: '10-27-2021',
          imgVideoPreviewSrc: productStory2VideoImage,
          video: 'https://www.youtube.com/embed/KBqp1c35eZ8',
          productImgSrc: productStory2ProductImage,
          detailsText: '<p>Unsere Steckdose mit Überspannungsschutz trägt dazu bei beispielsweise TV- oder Hi-Fi-Anlagen oder deinen Computer vor Stromschäden zu schützen.</li><li>Kombinierbar mit allen Schalterserien</li><li>Einfach austauschbar</li></ul></p>',
          productLink: 'https://www.busch-jaeger.de/produkt/busch-protector',
          findInstallerLink: 'https://www.busch-jaeger.de/installateur-finden',
        },
        {
          title: 'Wenn es stilsicher bleiben soll.',
          subtitle: 'Busch-axcent® pur',
          text: 'Mit Busch-axcent® pur setzt du optische Highlights.',
          publishDate: '10-20-2021',
          imgVideoPreviewSrc: productStory1VideoImage,
          video: 'https://www.youtube-nocookie.com/embed/sib-RDaFdtA?autoplay=1',
          productImgSrc: productStory1ProductImage,
          detailsText: '<p>Mach deine Lichtschalter zu Designobjekten und zeig deinen Gästen, dass du jedes Detail im Blick hast.</p><p><ul><li>Große Farbauswahl</li><li>Hochwertige Materialien</li><li>Vielfältige Einsatzmöglichkeiten</li></ul></p>',
          productLink: 'https://www.busch-jaeger.de/produkte/schalterdesign/busch-axcent-pur',
          findInstallerLink: 'https://www.busch-jaeger.de/installateur-finden',
        },
      ],
    },
  },
};
