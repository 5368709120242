<template>
  <div class="testimonial testimonial--left" :class="{'testimonial--inactive': !active}">
    <div class="testimonial__background">
      <div class="component__container">
        <div class="component__row">
          <div class="testimonial__image">
            <div
              class="testimonial__image-picture"
            >
              <image-responsive :src="imageSource.src" :variants="imageSource.variants" />
            </div>
          </div>

          <div class="testimonial__content">
            <h4
              class="testimonial__content-header"
            >
              „{{ quote }}“
            </h4>
            <div class="testimonial__content-body">
              <p>{{ author }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageResponsive from '../elements/ImageResponsive.vue';

export default {
  name: 'Testimonial',

  components: {
    ImageResponsive,
  },

  props: {
    imageSource: Object,
    quote: String,
    author: String,
    active: Boolean,
  },
};
</script>
