<template>
  <div class="pagination">
    <div
      class="pagination__dot"
      :class="{'pagination__dot--active': index === active}"
      v-for="(item, index) in items"
      :key="index"
      @click="$emit('click', index)"
    >

    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',

  props: {
    active: {
      type: Number,
      default: 0,
    },

    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
