<template>
  <div class="lead component component--has-background-overflow">
    <div class="component__container">
      <div class="lead__row">
        <div class="lead__col lead__col--content">
          <div class="lead__social lead__social--top">
            <a href="https://de-de.facebook.com/BUSCH.JAEGER.ELEKTRO/" target="_blank">
              <span class="mdi mdi-facebook"></span>
            </a>
            <a href="https://de.linkedin.com/company/busch-jaeger-elektro-gmbh" target="_blank">
              <span class="mdi mdi-linkedin"></span>
            </a>
            <a href="https://www.xing.com/companies/busch-jaegerelektrogmbh" target="_blank">
              <span class="mdi mdi-xing"></span>
            </a>
            <a href="https://www.instagram.com/buschjaeger/" target="_blank">
              <span class="mdi mdi-instagram"></span>
            </a>
            <a href="https://www.youtube.com/c/Busch-Jaeger" target="_blank">
              <span class="mdi mdi-youtube"></span>
            </a>
            <a href="http://www.pinterest.com/buschjaeger" target="_blank">
              <span class="mdi mdi-pinterest"></span>
            </a>
            <p class="lead__social-text">
              Inspiration für Ihr Zuhause finden Sie auch auf unseren Social Media Kanälen.
            </p>
          </div>
          <div style="position: relative;">
            <div class="lead__blue-background"></div>
            <div class="lead__form">
              <h3>Nichts mehr verpassen</h3>
              <p>
                Lass dich inspirieren: Mit unserem Newsletter
                bleibst du über alle Stories aus unserer Produktwelt
                auf dem Laufenden.
              </p>
              <a
                href="https://www.busch-jaeger.de/fuer-profis/service/newsletter"
                target="_blank"
                class="btn btn-white"
              >
                Jetzt abonnieren
              </a>
            </div>
          </div>
          <div class="lead__social lead__social--bottom">
            <p class="lead__social-text">
              Inspiration für Ihr Zuhause finden Sie auch auf unseren Social Media Kanälen.
            </p>
            <a href="https://de-de.facebook.com/BUSCH.JAEGER.ELEKTRO/" target="_blank">
              <span class="mdi mdi-facebook"></span>
            </a>
            <a href="https://de.linkedin.com/company/busch-jaeger-elektro-gmbh" target="_blank">
              <span class="mdi mdi-linkedin"></span>
            </a>
            <a href="https://www.xing.com/companies/busch-jaegerelektrogmbh" target="_blank">
              <span class="mdi mdi-xing"></span>
            </a>
            <a href="https://www.instagram.com/buschjaeger/" target="_blank">
              <span class="mdi mdi-instagram"></span>
            </a>
            <a href="https://www.youtube.com/c/Busch-Jaeger" target="_blank">
              <span class="mdi mdi-youtube"></span>
            </a>
            <a href="http://www.pinterest.com/buschjaeger" target="_blank">
              <span class="mdi mdi-pinterest"></span>
            </a>
          </div>
        </div>
      </div>
      <div class="lead__row">
        <div class="lead__col lead__col--images">
          <div class="lead__image-container">
            <transition-group name="fade-multiple" tag="div" class="lead__image-container-inner">
              <template
                v-for="(image, index) in imageGroups"
              >
                <image-responsive
                  v-if="index === currentIndex"
                  :key="index"
                  :src="image.src"
                  :variants="image.variants"
                />
              </template>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageResponsive from '../elements/ImageResponsive.vue';

const imageAssets1 = require.context('../assets/lead', false, /(slide1).*\.(png|jpe?g|svg)$/);
const images1 = imageAssets1.keys().map((path) => imageAssets1(path));

const imageAssets2 = require.context('../assets/lead', false, /(slide2).*\.(png|jpe?g|svg)$/);
const images2 = imageAssets2.keys().map((path) => imageAssets2(path));

const imageAssets3 = require.context('../assets/lead', false, /(slide3).*\.(png|jpe?g|svg)$/);
const images3 = imageAssets3.keys().map((path) => imageAssets3(path));

const imageAssets4 = require.context('../assets/lead', false, /(slide4).*\.(png|jpe?g|svg)$/);
const images4 = imageAssets4.keys().map((path) => imageAssets4(path));

const imageAssets5 = require.context('../assets/lead', false, /(slide5).*\.(png|jpe?g|svg)$/);
const images5 = imageAssets5.keys().map((path) => imageAssets5(path));

const imageAssets6 = require.context('../assets/lead', false, /(slide6).*\.(png|jpe?g|svg)$/);
const images6 = imageAssets6.keys().map((path) => imageAssets6(path));

const imageAssets7 = require.context('../assets/lead', false, /(slide7).*\.(png|jpeg|svg)$/);
const images7 = imageAssets7.keys().map((path) => imageAssets7(path));

/* eslint-disable max-len */
export default {
  name: 'Lead',

  components: {
    ImageResponsive,
  },

  data() {
    return {
      images: [
        images1,
        images2,
        images3,
        images4,
        images5,
        images6,
        images7,
      ],
      currentIndex: 0,
    };
  },

  mounted() {
    window.setInterval(() => {
      this.currentIndex = this.currentIndex + 1 >= this.images.length
        ? 0
        : this.currentIndex + 1;
    }, 4000);
  },

  computed: {
    imageGroups() {
      return this.images.map((image) => (
        {
          src: image.find((p) => !p.includes('_md') && !p.includes('_lg')),
          variants: image.filter((p) => p.includes('_md') || p.includes('_lg')),
        }
      ));
    },
  },
};
</script>
