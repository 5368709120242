<script>
export default {
  name: 'Transitioner',

  props: {
    visible: Boolean,
    transitionClass: String,
    applyInitialState: {
      type: Boolean,
      default: true,
    },
  },

  render() {
    return this.$slots.default;
  },

  mounted() {
    this.applyInitialStateClasses();
    this.attachTransitionEndListener();
    this.slotNodeClassList.add(this.transitionClasses.base);
  },

  watch: {
    transitionClass(newClass, oldClass) {
      this.cleanUpOld(oldClass);
      this.applyInitialStateClasses();
      this.slotNodeClassList.add(this.transitionClasses.base);
    },

    visible() {
      /* eslint-disable */
      return this.visible
          ? this.show()
          : this.hide();
    },
  },

  computed: {
    slotNode() {
      return this.$slots.default[0] || null;
    },

    slotNodeClassList() {
      return this.slotNode.context.$el.classList;
    },

    transitionClasses() {
      return {
        base: this.transitionClass,
        active: `${this.transitionClass}-active`,
        show: `${this.transitionClass}-in`,
        showed: `${this.transitionClass}-in-visible`,
        hide: `${this.transitionClass}-out`,
        hidden: `${this.transitionClass}-out-hidden`,
      };
    },
  },

  methods: {
    attachTransitionEndListener() {
      this.slotNode.context.$el.addEventListener('transitionend', () => {
        /* eslint-disable */
        console.log('transition ended', this.slotNode.context._uid, this.visible ? 'in' : 'out');

        return this.visible
          ? this.showed()
          : this.hidden();
      });
    },

    applyInitialStateClasses() {
      if (!this.applyInitialState) {
        return
      }

      return this.visible
          ? this.showed()
          : this.hidden();
    },

    cleanUpOld(transitionClass) {
        this.slotNodeClassList.remove(transitionClass);
        this.slotNodeClassList.remove(`${transitionClass}-active`);
        this.slotNodeClassList.remove(`${transitionClass}-out`);
        this.slotNodeClassList.remove(`${transitionClass}-out-hidden`);
        this.slotNodeClassList.remove(`${transitionClass}-in`);
        this.slotNodeClassList.remove(`${transitionClass}-in-visible`);
    },

    cleanUp() {
      this.slotNodeClassList.remove(this.transitionClasses.active);
      this.slotNodeClassList.remove(this.transitionClasses.hide);
      this.slotNodeClassList.remove(this.transitionClasses.hidden);
      this.slotNodeClassList.remove(this.transitionClasses.show);
      this.slotNodeClassList.remove(this.transitionClasses.showed);
    },

    show() {
      this.cleanUp();
      this.slotNodeClassList.add(this.transitionClasses.active);
      this.slotNodeClassList.add(this.transitionClasses.show);
    },

    showed() {
      this.cleanUp();
      this.slotNodeClassList.add(this.transitionClasses.showed);
    },

    hide() {
      this.cleanUp();
      this.slotNodeClassList.add(this.transitionClasses.active);
      this.slotNodeClassList.add(this.transitionClasses.hide);
    },

    hidden() {
      this.cleanUp();
      this.slotNodeClassList.add(this.transitionClasses.hidden);
    },
  },
};
</script>
