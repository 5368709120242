<template>
  <div
    class="component product-highlight"
    :style="moveTopBy"
    v-touch:swipe.left="onArrowRightClick"
    v-touch:swipe.right="onArrowLeftClick"
  >
    <div class="component__container product-highlight__inner">
      <div class="component__row">
        <!-- Left col -->
        <div class="product-highlight__col">
          <div class="product-highlight__content">
              <!-- Hightlight header -->
              <div class="product-highlight__header">
                <product-highlight-heading
                  v-for="(slide, index) in product.slides"
                  :key="index"
                  :heading="slide.heading"
                  :visible="index === activeIndex"
                  :direction="navigationDirection"
                />
              </div>
              <!-- Hightlight header -->

              <!-- Hightlight video -->
              <div class="product-highlight__video">
                <youtube-video
                  :video="product.video"
                  :videoImage="product.videoImage"
                />
              </div>
              <!-- Hightlight video -->
            </div>
        </div>
        <!-- Left col -->
        <!-- Right col -->
        <div class="product-highlight__col">
          <div class="product-highlight__slider-container">
            <!-- Product images -->
            <div
                class="product-highlight__images"
                :class="{
                  'product-highlight__images--variants-exist': activeIndex === 2 && isVariantsExist
                }"
            >
              <!-- Product background image -->
              <transition name="scale">
                <div v-if="activeIndex === 2" class="product-highlight__image-background">
                  <img :src="product.backgroundImage" alt="Produkt Hintergrund" />
                </div>
              </transition>
              <!-- Product background image -->
              <!-- Product main image -->
              <div
                ref="productImage"
                :style="moveProductImageTopBy"
                :class="{
                  'product-highlight__image-main--featured': activeIndex === 1,
                  'product-highlight__image-main--on-background': activeIndex === 2,
                }"
                class="product-highlight__image-main"
              >
                <!-- eslint-disable max-len -->
                <transition name="fade-product-image">
                  <img :src="product.image" alt="Produkt Bild" v-if="productVariantIndex === 0" />
                  <!-- Product variants -->
                  <div class="product-highlight__image-main__variants" v-if="isDifferentProductVariantChosen">
                    <transition name="fade-product-image">
                        <template
                          v-for="(variant, index) in productVariantsImages"
                        >
                            <div
                              v-if="productVariantIndex === index"
                              class="product-highlight__image-main__variant"
                              :key="index"
                            >
                                <img
                                    :src="variant"
                                    alt="Produkt Bild"
                                />
                            </div>
                        </template>
                    </transition>
                  </div>
                  <!-- Product variants -->
                </transition>
                <!-- eslint-enable max-len -->
              </div>
              <!-- Product main image -->
              <!-- Product feature top image -->
              <transition name="slide-top">
                <div
                  v-if="product.topImage && activeIndex === 1 && featuresShown"
                  class="product-highlight__image-feature product-highlight__image-feature--top">
                  <img :src="product.topImage" alt="Feature Bild" />
                </div>
              </transition>
              <!-- Product feature top image -->
              <!-- Product feature bottom image -->
              <transition name="fade">
                <div
                  v-if="product.bottomImage && activeIndex === 1 && featuresShown"
                  class="product-highlight__image-feature product-highlight__image-feature--bottom"
                >
                  <img :src="product.bottomImage" alt="Feature Bild" />
                </div>
              </transition>
              <!-- Product feature bottom image -->
              <!-- Product feature trigger -->
              <transition name="fade">
                <div
                  v-if="product.bottomImage && activeIndex === 1 && !featuresShown"
                  @click="featuresShown = true"
                  class="product-highlight__feature-trigger"
                >
                  <button>
                    {{ product.accentHeading }}
                  </button>
                </div>
              </transition>
              <!-- Product feature trigger -->
              <!-- Product variants -->
              <product-highlight-variants
                :activeIndex="activeIndex"
                :productVariantsImages="productVariantsImages"
                :imageVariants="product.imageVariants"
                :productVariantIndex="productVariantIndex"
                @variant-click="handleVariantClick"
              ></product-highlight-variants>
              <!-- Product variants -->
            </div>
            <!-- Product images -->
            <!-- Product texts -->
            <div class="product-highlight__text">
              <product-highlight-text
                v-for="(slide, index) in product.slides"
                :key="index"
                :heading="slide.subheading"
                :text="slide.text"
                :visible="index === activeIndex"
                :direction="navigationDirection"
              />
            </div>
            <!-- Product texts -->
            <!-- Product actions -->
            <!-- eslint-disable max-len -->
            <div class="product-highlight__actions">
              <a href="https://www.busch-jaeger.de/installateur-finden" target="_blank" class="product-module__button_call-to-action btn btn-primary btn-sm">
                Installateur finden
              </a>
              <a :href="product.productButtonLink" target="_blank" class="product-module__button_navigate btn btn-sm" :class="{'btn--highlight': activeIndex === 2}">
                Zur Produktseite
              </a>
            </div>
            <!-- eslint-enable max-len -->
            <!-- Product actions -->
          </div>
        </div>
        <!-- Right col -->
      </div>

      <pagination
        v-if="product.slides.length > 1"
        :items="product.slides"
        :active="activeIndex"
        @click="onPaginationClick"
      />

      <arrow-navigation
        v-if="product.slides.length > 1"
        :disabled-prev="activeIndex === 0"
        :disabled-next="activeIndex === product.slides.length - 1"
        @next="onArrowRightClick"
        @prev="onArrowLeftClick"
      />
    </div>
  </div>
</template>

<script>
import Position from '../utils/position';
import Breakpoints from '@/utils/breakpoints';

import ProductHighlightHeading from './ProductHighlightHeading.vue';
import ProductHighlightText from './ProductHighlightText.vue';
import ProductHighlightVariants from './ProductHighlightVariants.vue';

import ArrowNavigation from '@/elements/ArrowNavigation.vue';
import Pagination from '@/elements/Pagination.vue';
import YoutubeVideo from '@/elements/YoutubeVideo.vue';

export default {
  name: 'ProductHighlight',

  components: {
    ProductHighlightHeading,
    ProductHighlightText,
    ProductHighlightVariants,
    ArrowNavigation,
    Pagination,
    YoutubeVideo,
  },

  props: {
    introPosition: Object,
  },

  data() {
    return {
      activeIndex: 0,
      navigationDirection: 'next',
      featuresShown: false,
      introStarted: false,
      productVariantIndex: 0,
    };
  },

  mounted() {
    window.addEventListener('scroll', () => {
      if (Position.top(this.$refs.productImage) <= (window.scrollY + (window.innerHeight / 5))) {
        this.introStarted = true;
      }
    });
  },

  watch: {
    activeIndex() {
      this.featuresShown = false;
    },
  },

  computed: {
    moveTopBy() {
      if (!this.introPosition || !this.$el) {
        return null;
      }

      const top = Position.top(this.$el);
      return `margin-top: ${this.introPosition.bottom - top}px`;
    },

    moveProductImageTopBy() {
      console.log(this.$refs, this.$refs.productImage);
      if (!this.introPosition || !Breakpoints.isAbove('xs') || !this.$refs.productImage || this.introStarted) {
        return null;
      }

      // TODO: Remove hardcoded 60
      const top = this.introPosition.bottom - Position.top(this.$el);
      return `--y: ${this.introPosition.top - Position.top(this.$refs.productImage) - top + 60}px`;
    },

    product() {
      return this.$t('productModule');
    },

    productVariantsImages() {
      return [this.product.image, ...this.product.imageVariants];
    },

    productImage() {
      if (this.activeIndex === 2 && this.isVariantsExist) {
        return this.productVariantIndex === 0
          ? this.product.image
          : this.product.imageVariants[this.productVariantIndex];
      }
      return this.product.image;
    },

    isDifferentProductVariantChosen() {
      return this.activeIndex === 2
        && this.isVariantsExist
        && this.productVariantIndex !== 0;
    },

    isVariantsExist() {
      return this.product.imageVariants.length > 0;
    },
  },

  methods: {
    onArrowLeftClick() {
      if (this.activeIndex !== 0) {
        this.activeIndex -= 1;
        this.productVariantIndex = 0;
        this.navigationDirection = 'prev';
      }
    },
    onArrowRightClick() {
      console.log(this.$refs, this.$refs.productImage);
      if (this.activeIndex !== 2) {
        this.activeIndex += 1;
        this.navigationDirection = 'next';
      }
    },
    onPaginationClick(index) {
      this.activeIndex = index;
    },
    handleVariantClick(index) {
      this.productVariantIndex = index;
    },
  },
};
</script>
