<template>
  <div class="youtube-video">
    <div class="youtube-video__inner">
      <div class="youtube-video__placeholder" v-if="!isVideoPlaying">
        <div class="youtube-video__preview-image" v-if="videoImage">
          <img :src="videoImage" alt="">
        </div>
        <div class="youtube-video__play">
          <a href="#" @click.prevent="playVideo" class="youtube-video__play--inner">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.750488 0.13562V25.416L24.9637 12.8189L0.750488 0.13562Z"
                fill="#EAEAEA"
              />
            </svg>
          </a>
        </div>
      </div>
      <!-- eslint-disable max-len -->
      <iframe v-else width="560" height="315" :src="video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen/>
      <!-- eslint-enable max-len -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'YoutubeVideo',
  data() {
    return {
      isVideoPlaying: false,
    };
  },
  props: {
    video: {
      type: String,
      required: true,
    },
    videoImage: String,
  },
  methods: {
    playVideo() {
      this.isVideoPlaying = true;
    },
  },
};
</script>
