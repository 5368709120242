import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vue2TouchEvents from 'vue2-touch-events';
import messages from './localization';
import App from './App.vue';
import './sass/main.scss';

Vue.use(Vue2TouchEvents);
Vue.use(VueI18n);
Vue.config.productionTip = false;

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'de', // set locale
  messages, // set locale messages
});

new Vue({
  i18n,
  render: (h) => h(App),
  mounted() {
    // This enables prerendering of static app files done by prerender spa plugin
    document.dispatchEvent(new Event('render-event'));
  },
}).$mount('#app');
