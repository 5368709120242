import breakpoints from '../sass/_export.scss';

const orderedBreakpoints = Object.keys(breakpoints).map((key) => ({
  key,
  value: parseInt(breakpoints[key], 10),
})).sort((a, b) => (a.value - b.value));

class Breakpoints {
  constructor() {
    this.breakpoints = orderedBreakpoints;
    // calculate current
    this.currentViewportWidth = this.getViewportWidth();
    this.currentBreakpoint = this.getCurrentBreakpoint();
    // holder for handlers
    this.handlers = [];
    // register resize handler
    window.addEventListener('resize', this.resizeHandler.bind(this));
  }

  resizeHandler() {
    // save old breakpoint
    const oldBreakpoint = this.currentBreakpoint;

    this.currentViewportWidth = this.getViewportWidth();
    this.currentBreakpoint = this.getCurrentBreakpoint();
    if (oldBreakpoint.key === this.currentBreakpoint.key) {
      return;
    }
    // execute all handler for the current breakpoint
    this.executeHandlers(this.currentBreakpoint, oldBreakpoint);
  }

  registerHandler(handler) {
    this.handlers.push(handler);
  }

  executeHandlers(current, old) {
    this.handlers.forEach((handler) => {
      // execute the handler
      handler(current, old);
    });
  }

  /* eslint-disable class-methods-use-this */
  getViewportWidth() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  }
  /* eslint-enable class-methods-use-this */

  getCurrentBreakpoint() {
    let currentBreakpoint = null;
    // is meant to work with min-width mq's
    this.breakpoints.forEach((breakpoint) => {
      if (breakpoint.value <= this.currentViewportWidth) {
        currentBreakpoint = breakpoint;
      }
    });

    return currentBreakpoint;
  }

  isAbove(key) {
    const index = this.breakpoints.findIndex((breakpoint) => breakpoint.key === key);
    /* eslint-disable max-len */
    const current = this.breakpoints.findIndex((breakpoint) => breakpoint.key === this.currentBreakpoint.key);
    /* eslint-enable max-len */

    return current > index;
  }

  isBelow(key) {
    const index = this.breakpoints.findIndex((breakpoint) => breakpoint.key === key);

    /* eslint-disable max-len */
    const current = this.breakpoints.findIndex((breakpoint) => breakpoint.key === this.currentBreakpoint.key);
    /* eslint-enable max-len */

    return current < index;
  }
}

export default new Breakpoints();
