<template>
  <div class="jump component">
    <div class="component__container">
      <div class="component__row">
        <div class="jump__col">
          <h2>Dein Elektroinstallateur vor Ort</h2>
          <p>
            Wie die Busch-Jaeger Produkte zu dir nach Hause kommen? Ganz einfach:
          </p>
          <ul>
            <li>Formular öffnen</li>
            <li>Wunschprodukt angeben </li>
            <li>Postleitzahl eintragen</li>
          </ul>
          <p style="margin-bottom: 60px;">
            Du erhältst Vorschläge für Installateure aus deiner Region.
          </p>
          <a
            href="https://www.busch-jaeger.de/installateur-finden"
            class="btn btn-primary"
            target="_blank"
            :class="{'btn-block': breakpoint === 'xs'}"
          >
            Installateur finden
          </a>
        </div>
        <div class="jump__col">
          <image-responsive
            :src="src"
            :variants="variants"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breakpoints from '../utils/breakpoints';
import ImageResponsive from '../elements/ImageResponsive.vue';

import src from '../assets/jump/jump.png';
import srcMd from '../assets/jump/jump_md.png';
import srcLg from '../assets/jump/jump_lg.png';

export default {
  name: 'Jump',

  components: {
    ImageResponsive,
  },

  data() {
    return {
      breakpoint: 'xs',
      src,
      variants: [srcLg, srcMd],
    };
  },

  mounted() {
    // Inject current breakpoint key
    this.breakpoint = Breakpoints.currentBreakpoint.key;
    // Register breakpoint handler to enebale or disable behaviour on breakpoint change
    Breakpoints.registerHandler(this.onBreakpointChange.bind(this));
  },

  methods: {
    onBreakpointChange(breakpoint) {
      this.breakpoint = breakpoint.key;
    },
  },
};
</script>
