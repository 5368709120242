<template>
  <div class="component testimonials">
    <carousel
      ref="carousel"
      :per-page="1"
      :navigation-enabled="false"
      :pagination-enabled="false"
      @pageChange="onCarouselPageChange"
    >
      <slide
        v-for="(item, index) in items"
        :key="index"
      >
        <testimonial
          :image-source="item.image"
          :quote="item.quote"
          :author="item.author"
          :active="activeIndex === index"
        />
      </slide>
    </carousel>

    <pagination
      v-if="items.length > 1"
      :active="activeIndex"
      :items="items"
      @click="onDotClick"
    />

    <arrow-navigation
      v-if="items.length > 1"
      :disabled-prev="activeIndex === 0"
      :disabled-next="activeIndex === items.length - 1"
      @prev="onArrowLeftClick"
      @next="onArrowRightClick"
    />

  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

import Testimonial from './Testimonial.vue';
import Pagination from '../elements/Pagination.vue';
import ArrowNavigation from '../elements/ArrowNavigation.vue';

export default {
  name: 'Testimonials',

  components: {
    Carousel,
    Slide,
    Testimonial,
    Pagination,
    ArrowNavigation,
  },

  data() {
    return {
      activeIndex: 0,
    };
  },

  computed: {
    items() {
      return this.$t('testimonials');
    },
  },

  methods: {
    onCarouselPageChange(index) {
      this.activeIndex = index;
    },
    onDotClick(index) {
      this.$refs.carousel.goToPage(index);
    },
    onArrowLeftClick() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage());
    },
    onArrowRightClick() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage());
    },
  },
};
</script>
