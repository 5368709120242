<template>
  <transitioner
    :visible="visible"
    :transition-class="direction === 'next' ? 'slide-left' : 'slide-right'"
  >
    <div class="product-highlight__text-item">
      <p v-if="heading"><strong>{{ heading }}</strong></p>
      <p>{{ text }}</p>
    </div>
  </transitioner>
</template>
<script>
import Transitioner from '../elements/Transitioner.vue';

export default {
  name: 'ProductHighlightHeading',

  components: {
    Transitioner,
  },

  props: {
    heading: String,
    text: String,
    visible: Boolean,
    direction: String,
  },
};
</script>
