<template functional>
  <footer>
    <div class="disclaimer">
      <div class="disclaimer__content">
        <div class="disclaimer__content-inner">
          <div class="disclaimer__copyright">© Busch-Jaeger Elektro GmbH 2021</div>
          <div class="disclaimer__legal">
            <a href="https://www.busch-jaeger.de/datenschutz" target="_blank">Datenschutz</a>
            <a href="https://www.busch-jaeger.de/einwilligungserklaerung" target="_blank">Einwilligungserklärung</a>
            <a href="https://www.busch-jaeger.de/media/content/allgemein/2327_a_112015_Allgemeine_Verkaufsbedingungen_fuer_Erzeugnisse_und_Leistungen_der_Elektroindustrie.pdf" target="_blank" >AGB</a>
            <a href="https://www.busch-jaeger.de/media/content/allgemein/ABB-Allgemeine-Lieferbedingungen-GL2018.pdf" target="_blank">Liefer-AGB</a>
            <a href="https://www.busch-jaeger.de/impressum" target="_blank">Impressum</a>
            <a href="https://www.busch-jaeger.de/kontakt" target="_blank">Kontakt</a>
          </div>
          <div class="disclaimer__social">
            <a href="https://de-de.facebook.com/BUSCH.JAEGER.ELEKTRO/" target="_blank">
              <span class="mdi mdi-facebook"></span>
            </a>
            <a href="https://de.linkedin.com/company/busch-jaeger-elektro-gmbh" target="_blank">
              <span class="mdi mdi-linkedin"></span>
            </a>
            <a href="https://www.xing.com/companies/busch-jaegerelektrogmbh" target="_blank">
              <span class="mdi mdi-xing"></span>
            </a>
            <a href="https://www.instagram.com/buschjaeger/" target="_blank">
              <span class="mdi mdi-instagram"></span>
            </a>
            <a href="https://www.youtube.com/c/Busch-Jaeger" target="_blank">
              <span class="mdi mdi-youtube"></span>
            </a>
            <a href="http://www.pinterest.com/buschjaeger" target="_blank">
              <span class="mdi mdi-pinterest"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  functional: true,
};
</script>
