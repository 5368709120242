<template>
  <div class="story-item" :class="{'story-item--visible': isVisible}">
    <div class="story-item__icons">
      <!-- TODO: Add icon here 45x45 -->
      <img :src="icon" style="width: auto; height: 50px;" />
    </div>
    <div class="story-item__heading">
      <h3>{{ headline }}</h3>
      <div class="story-item__line" :class="{'story-item__line--active': isVisible}"></div>
    </div>
    <div class="story-item__content">
      <p>{{ copy }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StoryItem',

  props: {
    icon: String,
    headline: String,
    copy: String,
    isVisible: Boolean,
  },
};
</script>
