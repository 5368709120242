<template>
  <div class="hero">
    <div
      class="hero__header"
      ref="hero"
    >
      <div class="hero__header-background" ref="heroBackground">
        <image-responsive :src="image.src" :variants="image.variants" />
      </div>
      <div class="hero__header-body container">
        <div class="hero__main" ref="heroHeaderText">
          <div class="hero__main-subtitle">
            <p v-html="$t('hero.header.subtitle')"></p>
          </div>
          <div class="hero__main-title">
            <h2 v-html="$t('hero.header.title')"></h2>
          </div>
        </div>
      </div>
    </div>
    <div
      class="hero__intro"
      :class="{'hero__intro--active': isScrollAnimationActive}"
      ref="intro"
    >
      <div class="hero__intro-inner">
        <div class="hero__intro-container">
          <div class="hero__intro-body">
            <div class="hero__intro-main">
              <h2>
                <span
                  class="hero__intro-heading__line-odd"
                  v-html="$t('hero.intro.heading.lineOne')"
                ></span>
                <span
                  class="hero__intro-heading__line-even"
                  v-html="$t('hero.intro.heading.lineTwo')"
                ></span>
                <span
                  class="hero__intro-heading__line-odd"
                  v-html="$t('hero.intro.heading.lineThree')"
                ></span>
                <span
                  class="hero__intro-heading__line-even"
                  v-html="$t('hero.intro.heading.lineFour')"
                ></span>
              </h2>
            </div>
            <div class="hero__intro-secondary" ref="heroIntroSecondary">
              <p v-html="$t('hero.intro.paragraph')"></p>
            </div>

          </div>
        </div>
        <div class="hero__intro-background" ref="heroIntroBackground"></div>
      </div>
    </div>
  </div>
</template>
<script>
import gsap from 'gsap';
import Position from '../utils/position';
import ImageResponsive from '../elements/ImageResponsive.vue';

export default {
  name: 'Hero',

  components: {
    ImageResponsive,
  },

  data() {
    return {
      isScrollAnimationActive: false,
    };
  },

  mounted() {
    // Emit refs positions
    /* eslint-disable max-len */
    const introCopyText = {
      top: Position.top(this.$refs.heroIntroSecondary),
      bottom: Position.top(this.$refs.heroIntroSecondary) + this.$refs.heroIntroSecondary.clientHeight,
    };
    /* eslint-enable max-len */

    this.$emit('mounted', introCopyText);

    this.$nextTick(() => {
      this.initScrollAnimation();
    });
  },

  computed: {
    image() {
      return this.$t('hero.header.image');
    },
  },

  methods: {
    initScrollAnimation() {
      this.isScrollAnimationActive = true;
      // Setup scroll animation
      const blurProgress = { value: 0 };
      const tl = gsap.timeline({ default: { duration: 0.5 } });
      tl.fromTo(this.$refs.intro, { opacity: 0 }, { opacity: 1 }, '<')
        .fromTo(this.$refs.heroHeaderText, { translateX: '0', opacity: 1 }, { translateX: '-100%', opacity: 0, ease: 'power1.out' }, '<')
        .fromTo(blurProgress, { value: 0 }, {
          value: 15,
          onUpdate: () => {
            if (this.$refs.heroBackground) {
              this.$refs.heroBackground.style.filter = `blur(${Math.round(blurProgress.value)}px)`;
            }
          },
        }, 0.2)
        .fromTo(this.$refs.heroBackground, { scale: 1, opacity: 1 }, { scale: 1.5, opacity: 0.5 }, '<')
        .fromTo('.hero__intro-heading__line-odd', { translateX: '-100%', opacity: 0 }, { translateX: 0, opacity: 1 }, '<')
        .fromTo('.hero__intro-heading__line-even', { translateX: '100%', opacity: 0 }, { translateX: 0, opacity: 1 }, '<')
        .fromTo(this.$refs.heroIntroSecondary, { opacity: 0, translateY: '100%' }, { translateY: 0, opacity: 1 }, '<')
        .fromTo(this.$refs.heroIntroBackground, { opacity: 0, translateY: '20%' }, { translateY: 0, opacity: 1 }, '<');

      tl.pause();
      // Make intro have normal scroll bahviour again
      tl.eventCallback('onComplete', () => {
        this.isScrollAnimationActive = false;
      });
      // Add fixed position back again (when scrolling up)
      tl.eventCallback('onUpdate', () => {
        if (!this.isScrollAnimationActive) {
          this.isScrollAnimationActive = true;
        }
      });
      // control the progress by scroll
      window.addEventListener('scroll', () => tl.progress(window.pageYOffset / window.innerHeight));
    },
  },
};
</script>
