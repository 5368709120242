<template>
  <div class="logo">
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="196" height="66" fill="none" viewBox="0 0 196 66">
        <path :fill="letterFill" fill-rule="evenodd" d="M52.068 32.876c0 1.183-.782 1.958-2.328 1.958-.546 0-1-.04-1.291-.078v-3.662c.255-.02.855-.02 1.037-.02 1.545 0 2.582.523 2.582 1.802zm-.2-5.541c0 1.163-.837 1.86-2.456 1.86-.381 0-.672 0-.963-.02v-3.41c.419-.019.89-.038 1.382-.038 1.49 0 2.037.64 2.037 1.608zm2.728 5.484c0-1.318-.728-2.597-2.183-2.927v-.038a3.166 3.166 0 0 0 1.946-2.927c0-2.092-1.473-3.235-4.128-3.235-1.51 0-3.11.038-4.146.096v13.06c1.546 0 2.837.04 3.582.04 3.71 0 4.929-2.17 4.929-4.07zM68.213 31.636v-7.944h-2.435v8.098c0 2.213-.793 3.02-2.682 3.02-2.113 0-2.642-1.153-2.642-2.789v-8.33H58v8.484c0 2.482.906 4.713 4.927 4.713 3.36 0 5.286-1.693 5.286-5.252zM79.277 32.723c0-3.995-5.155-3.143-5.155-5.434 0-.91.69-1.552 2.051-1.552.744 0 1.61.161 2.3.37l.023-2.188c-.726-.133-1.524-.227-2.215-.227-2.94 0-4.664 1.571-4.664 3.843 0 4.203 5.137 3.086 5.137 5.528 0 1.174-.926 1.685-2.124 1.685-.889 0-1.98-.237-2.67-.483l-.034 2.32c.762.208 1.633.303 2.504.303 3.013 0 4.847-1.761 4.847-4.165zM91.191 36.313l-.288-2.221c-.675.305-1.734.536-2.524.536-2.62 0-3.795-1.571-3.795-4.386 0-2.51 1.195-4.329 3.621-4.329a9.32 9.32 0 0 1 2.62.383l.27-2.26a10.87 10.87 0 0 0-2.754-.344c-4.354 0-6.511 2.872-6.511 6.971 0 3.218 1.464 6.225 6.01 6.225 1.213 0 2.388-.23 3.351-.575zM103.957 36.888V23.692h-2.432v5.278h-5.349v-5.278h-2.431v13.196h2.431V31.16h5.349v5.727h2.432z" clip-rule="evenodd"/>
        <mask id="a" width="160" height="46" x="18" y="12" maskUnits="userSpaceOnUse">
            <path fill="#fff" fill-rule="evenodd" d="M18 58h160V12.255H18V58z" clip-rule="evenodd"/>
        </mask>
        <g fill-rule="evenodd" clip-rule="evenodd" mask="url(#a)">
            <path :fill="letterFill" d="M107.362 31.609h4.255v-1.76h-4.255v1.76zM118.426 33.068v-9.376h-2.496v9.28c0 1.407-.518 1.793-1.42 1.793-.461 0-.883-.077-1.191-.192v2.161c.442.077.922.154 1.614.154 2.628 0 3.493-1.678 3.493-3.82zM127.746 32.177h-3.59l1.165-3.363a24.43 24.43 0 0 0 .611-2.21h.019c.152.743.344 1.389.592 2.112l1.203 3.46zm4.296 4.71l-4.6-13.195h-2.809l-4.506 13.196h2.502l.897-2.698h4.869l.898 2.698h2.749zM141.404 36.888v-2.17h-4.397V31.14h4.175v-2.072h-4.175v-3.246h4.397v-2.13h-6.808v13.196h6.808zM154.964 24.242c-1.122-.322-2.399-.55-3.695-.55-4.526 0-7.311 2.867-7.311 6.892 0 3.911 2.455 6.304 6.595 6.304 1.567 0 3.173-.285 4.468-.798v-6.113h-2.513v4.461a5.69 5.69 0 0 1-1.664.228c-2.651 0-4.16-1.595-4.16-4.31 0-2.867 1.857-4.462 4.43-4.462a9.83 9.83 0 0 1 3.463.646l.387-2.298zM165.234 36.888v-2.17h-4.396V31.14h4.174v-2.072h-4.174v-3.246h4.396v-2.13h-6.808v13.196h6.808zM174.5 27.637c0 1.224-.929 2.001-2.438 2.001-.387 0-.735-.019-.91-.038v-3.79c.253-.039.619-.058.988-.058 1.585 0 2.36.622 2.36 1.885zm3.5 9.25l-3.133-4.411c-.329-.447-.697-.953-1.064-1.438 1.973-.311 3.326-1.71 3.326-3.654 0-2.682-1.856-3.692-4.737-3.692-1.51 0-2.729.02-3.754.077v13.119h2.514v-5.422h.098l3.636 5.422H178z"/>
            <path :fill="brandFill" d="M34.452 32.625c1.255 0 2.272 1.035 2.272 2.312 0 1.278-1.017 2.313-2.272 2.313H20.303c-1.255 0-2.271-1.035-2.271-2.313 0-1.277 1.016-2.312 2.271-2.312h14.149zm-4.127-2.154c-1.118 0-2.023-.922-2.023-2.06a2.06 2.06 0 0 1 1.218-1.889c1.756-.845 3.015-2.671 3.015-4.779 0-2.913-2.32-5.275-5.182-5.275-2.863 0-5.182 2.362-5.182 5.275 0 2.103 1.299 4.068 2.986 4.777.742.313 1.215.937 1.215 1.891 0 1.138-.906 2.06-2.023 2.06-.384 0-.74-.053-1.047-.242C20.167 28.714 18 25.463 18 21.695c0-5.213 4.152-9.44 9.275-9.44 5.122 0 9.274 4.227 9.274 9.44 0 3.808-1.976 7.038-5.166 8.53-.349.188-.684.245-1.058.245zM18.382 44.34c-.133-.47-.35-.927-.35-1.452a3.082 3.082 0 0 1 3.08-3.105h3.284c1.143 0 2.07.943 2.07 2.107a2.062 2.062 0 0 1-2.07 2.075h-1.478l4.295 7.54 4.544-7.54h-1.48a2.061 2.061 0 0 1-2.068-2.075c0-1.164.926-2.107 2.069-2.107h3.284a3.083 3.083 0 0 1 3.08 3.105c0 .535-.133 1.016-.364 1.457l-7.391 12.818a1.978 1.978 0 0 1-1.612.836c-.663 0-1.226-.348-1.587-.855l-7.283-12.8-.023-.003z"/>
        </g>
    </svg>
    <!-- eslint-enable max-len -->
  </div>
</template>

<script>
export default {
  name: 'Logo',

  props: {
    letterFill: {
      type: String,
      default: '#1D1D1B',
    },
    brandFill: {
      type: String,
      default: '#009EE2',
    },
  },
};
</script>
