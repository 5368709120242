<template>
  <div class="product-story">
    <div class="product-story__inner" :class="{'product-story__details--active': isDetailsClicked}">
      <div class="product-story__states">
        <div class="product-story__top" ref="cardTop">
          <div class="product-story__video">
            <youtube-video
              :video="video"
              :videoImage="imgVideoPreviewSrc"
            ></youtube-video>
          </div>
          <div class="product-story__body">
            <div class="product-story__container">
              <div class="product-story__body-header">
                <div class="product-story__general-info">
                  <div class="product-story__episode">
                    <p>
                      {{ episode }}
                    </p>
                  </div>
                  <div class="product-story__publish-date">
                    <p>
                      {{ publishDateRange }}
                    </p>
                  </div>
                </div>
                <div class="product-story__title">
                  <h4>
                    {{ title }}
                  </h4>
                </div>
              </div>
              <div class="product-story__body-text">
                <p>
                  {{ text }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="product-story__bottom" ref="cardBottom">
          <div class="product-story__container">
            <div class="product-story__subtitle">
              <p ref="cardSubtitle">
                <strong>
                  {{ subtitle }}
                </strong>
              </p>
            </div>
            <div class="product-story__bottom-text__wrapper" ref="cardBottomText">
              <div class="product-story__bottom-text" v-html="detailsText" />
              <div class="product-story__bottom-links">
                <a :href="productLink" class="btn btn-primary btn-sm" target="_blank">
                  Zur Produktseite
                </a>
                <a :href="findInstallerLink" class="btn btn-sm" target="_blank">
                  Installateur Finden
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="product-story__item-image" ref="cardImageWrapper">
        <div class="product-story__container">
          <img :src="productImgSrc" alt="" ref="cardImage">
        </div>
      </div>
      <div class="product-story__actions">
        <div class="product-story__container">
          <a
            href="#"
            class="product-story__details-button"
            @click.prevent="handleDetailsButtonClick"
          >
            <span class="product-story__arrow" ref="arrow">
              <img src="../assets/arrow_up.svg" alt="">
            </span>
            <transition name="fade-text" mode="out-in">
              <span
                class="product-story__details-button-text"
                key="checkVideo"
                v-if="isDetailsClicked"
              >
                  Zurück zum Video
              </span>
              <span class="product-story__details-button-text" key="checkDetails" v-else>
                Mehr zum Produkt
              </span>
            </transition>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gsap from 'gsap';

import YoutubeVideo from '@/elements/YoutubeVideo.vue';

export default {
  name: 'ProductStory',
  components: {
    YoutubeVideo,
  },
  props: {
    index: Number,
    title: String,
    subtitle: String,
    text: String,
    publishDate: String,

    video: String,
    imgVideoPreviewSrc: String,
    productImgSrc: String,

    detailsText: String,

    productLink: String,
    findInstallerLink: String,
  },
  data() {
    return {
      isDetailsClicked: false,
      arrowTimeline: null,
      detailsTimeline: null,
    };
  },

  mounted() {
    this.arrowTimeline = this.createArrowTimeline();
    this.detailsTimeline = this.createDetailsTimeline();
  },

  computed: {
    publishDateRange() {
      const pDate = new Date(this.publishDate);
      const today = new Date();
      const diffDays = Math.ceil((today.getTime() - pDate.getTime()) / (1000 * 3600 * 24));
      let dateRangeString = 'Heute';

      // One day
      if (diffDays === 1) dateRangeString = '1 Tag';
      // More days
      if (diffDays > 1) dateRangeString = `${diffDays} Tage`;
      // One week
      if (diffDays === 7) dateRangeString = '1 Woche';
      // More weeks
      if (diffDays >= 14) dateRangeString = `${Math.round(diffDays / 7)} Wochen`;

      return dateRangeString;
    },

    episode() {
      return `Folge ${(this.index + 1).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}`;
    },
  },

  methods: {
    createArrowTimeline() {
      return gsap
        .timeline({ paused: true })
        .fromTo(this.$refs.arrow, { rotate: 0 }, { rotate: '180deg', duration: 0.6 });
    },
    createDetailsTimeline() {
      return gsap.timeline({ paused: true })
        .fromTo(this.$refs.cardTop, { translateY: 0 }, { translateY: '-100%', duration: 0.4 })
        .fromTo(this.$refs.cardSubtitle, { fontSize: '14px' }, { fontSize: '18px', duration: 0.4 }, '<')
        .fromTo(this.$refs.cardImage, {}, { width: '160px', height: '160px', duration: 0.4 }, '<');
    },

    handleDetailsButtonClick() {
      this.isDetailsClicked = !this.isDetailsClicked;
      if (this.isDetailsClicked) {
        this.arrowTimeline.play();
        this.animateSlideTop();
      } else {
        this.arrowTimeline.reverse();
        this.animateSlideBottom();
      }
    },
    animateSlideTop() {
      this.detailsTimeline.play();
      gsap.timeline()
        .fromTo(this.$refs.cardImageWrapper, { }, { top: '-80px', bottom: '100%', duration: 0.4 })
        .fromTo(this.$refs.cardBottom, { translateY: '86%' }, { translateY: '20%' }, '<')
        .fromTo(this.$refs.cardBottomText, { opacity: '0' }, { opacity: '1' }, '<');
    },
    animateSlideBottom() {
      this.detailsTimeline.reverse();
      const { cardImageWrapper, cardBottom, cardBottomText } = this.$refs;
      gsap.timeline()
        .fromTo(cardImageWrapper, { bottom: '100%', top: '-80px' }, {
          bottom: '-50px',
          duration: 0.4,
          onStart() {
            cardImageWrapper.style.top = 'auto';
          },
        })
        .fromTo(cardBottom, { translateY: '20%' }, { translateY: '86%' }, '<')
        .fromTo(cardBottomText, { opacity: '1' }, { opacity: '0', duration: 0.4 }, '<');
    },
  },
};
</script>
<style lang="scss" scoped>
  .fade-text-enter-active,
  .fade-text-leave-active {
    transition: opacity 0.3s ease-in-out;
  }

  .fade-text-enter-to {
    opacity: 1;
  }

  .fade-text-leave-to,
  .fade-text-enter {
    opacity: 0.2;
  }
</style>
