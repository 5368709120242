<template>
  <div class="component product-stories">
    <div class="product-stories__inner">
      <div v-if="breakpoint !== 'lg'" class="component__container">
        <div class="component__row">
          <div class="col">
            <div class="product-stories__header">
              <h2 v-html="$t('productStory.title')"></h2>
              <p v-html="$t('productStory.text')"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="product-stories__teaser-container">
        <div class="component__row">
          <div class="product-stories__carousel">
            <carousel
              ref="carousel"
              :per-page="teaserPerSlide"
              :scroll-per-page="false"
              :navigation-enabled="false"
              :pagination-enabled="false"
              @pageChange="onCarouselPageChange"
            >
              <slide v-if="breakpoint === 'lg'">
                <div class="product-stories__header">
                  <h2 v-html="$t('productStory.title')"></h2>
                  <p v-html="$t('productStory.text')"></p>
                </div>
              </slide>
              <slide
                v-for="(product, index) in products"
                :key="index"
              >
                <product-story
                  :index="countDecreasingIndex(products, index)"
                  :title="product.title"
                  :subtitle="product.subtitle"
                  :text="product.text"
                  :episode="product.episode"
                  :publishDate="product.publishDate"

                  :video="product.video"
                  :imgVideoPreviewSrc="product.imgVideoPreviewSrc"
                  :productImgSrc="product.productImgSrc"

                  :detailsText="product.detailsText"

                  :productLink="product.productLink"
                  :findInstallerLink="product.findInstallerLink"
                ></product-story>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
      <div class="component__container" v-if="maxSliderValue >= teaserPerSlide">
        <div class="component__row">
          <div class="product-stories__slider">
            <vue-slider
              tooltip="none"
              v-model="index"
              :max="maxSliderValue"
              :dot-size="45"
              :height="2"
              @change="onSliderValueChange"
            >
              <template v-slot:dot>
                <div class="btn-square product-stories__slider-dot">
                  <img src="../assets/arrow_slider.svg" />
                </div>
              </template>
            </vue-slider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import Breakpoints from '../utils/breakpoints';
import ProductStory from '@/components/ProductStory.vue';

export default {
  name: 'ProductStories',
  components: {
    Carousel,
    Slide,
    ProductStory,
    VueSlider,
  },

  data() {
    return {
      breakpoint: 'xs',
      index: 0,
    };
  },

  mounted() {
    // Inject current breakpoint
    this.breakpoint = Breakpoints.currentBreakpoint.key;
    // Register breakpoint handler to switch ammount of teasers shown per page
    Breakpoints.registerHandler(this.onBreakpointChange.bind(this));
  },

  computed: {
    products() {
      return this.$t('productStory.products');
    },
    maxSliderValue() {
      // Calculate the maximum value for the slider comp, depends on breakpoint too
      return this.products.length - this.teaserPerSlide + (this.breakpoint === 'lg' ? 1 : 0);
    },
    teaserPerSlide() {
      switch (this.breakpoint) {
        case 'xs':
          return 1;
        case 'md':
          return 2;
        case 'lg':
          return 3;
        default:
          return 2;
      }
    },
  },

  methods: {
    onBreakpointChange(breakpoint) {
      this.breakpoint = breakpoint.key;
    },
    onCarouselPageChange(index) {
      this.index = index;
    },
    onSliderValueChange(value) {
      this.$refs.carousel.goToPage(value);
    },
    countDecreasingIndex(array, index) {
      return array.length - index - 1;
    },
  },
};
</script>
