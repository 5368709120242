<template>
  <transitioner
    :visible="visible"
    :transition-class="direction === 'next' ? 'slide-left' : 'slide-right'"
  >
    <div class="product-highlight__header-item">
      <h2>{{ heading }}</h2>
    </div>
  </transitioner>
</template>
<script>
import Transitioner from '../elements/Transitioner.vue';

export default {
  name: 'ProductHighlightHeading',

  components: {
    Transitioner,
  },

  props: {
    heading: String,
    visible: Boolean,
    direction: String,
  },
};
</script>
