<template>
  <!-- eslint-disable max-len -->
  <header class="header"
    :class="{
      'header--transparent': scrollPosition < scrollTreshold.color && !isNavVisible,
      'header--hidden': scrollDirection === 'down' && !isHovered && scrollPosition > scrollTreshold.hidden,
      'header--text-light': lightUi,
    }"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
  <!-- eslint-enable max-len -->

    <div class="header__container">
      <div class="header__row">
        <div class="header__column">
          <logo
            @click.native="scrollTop"
            :letterFill="logoFill.letters"
            :brandFill="logoFill.brand"
          />
          <div class="navigation" :class="{'navigation--active': isNavVisible}">
            <nav>
              <ul class="navigation__list" ref="navList">
                <!-- TODO: Add smooth scroll to link targets -->
                <!-- TODO: Add scroll spying for active state in desktop mode -->
                <li class="navigation__list-item">
                  <a @click.stop.prevent="scrollTo('hero')">Intro</a>
                </li>
                <li class="navigation__list-item">
                  <a @click.stop.prevent="scrollTo('productHighlight')">Story der Woche</a>
                </li>
                <li class="navigation__list-item">
                  <a @click.stop.prevent="scrollTo('productStories')">
                    Alle Episoden
                    <span class="navigation__list-item-badge">{{ productStoriesCount }}</span>
                  </a>
                </li>
                <li class="navigation__list-item">
                  <a @click.stop.prevent="scrollTo('story')">Darum Busch-Jaeger</a>
                </li>
                <li class="navigation__list-item">
                  <a @click.stop.prevent="scrollTo('jump')">Installateur finden </a>
                </li>
                <li class="navigation__list-item navigation__list-item--special">
                  <a href="https://www.busch-jaeger.de/" target="_blank">
                    busch-jaeger.de
                    <span>
                      <img src="../assets/arrow_up_right.svg" alt="Pfeil oben rechts">
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
            <div class="navigation__mobile-trigger" @click="toggleNavList">
              <span class="mdi mdi-close" v-show="isNavVisible"></span>
              <span class="mdi mdi-menu" v-show="!isNavVisible"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="scrollDirection === 'down'" class="header__hover-trigger"></div>
  </header>
</template>

<script>
import Logo from '../elements/Logo.vue';
import Position from '../utils/position';

export default {
  name: 'AppHeader',

  components: {
    Logo,
  },

  props: {
    targets: Object,
    productStoriesCount: Number,
  },

  data() {
    return {
      states: [],
      scrollTreshold: {
        color: window.innerHeight,
        hidden: 50,
      },
      scrollPosition: 0,
      scrollDirection: 'down',
      isHovered: false,
      isNavVisible: false,
      // TODO: Make this value dynamic based on hero bg image brightness
      lightUi: false,
    };
  },

  mounted() {
    window.addEventListener('scroll', this.onWindowScroll.bind(this));
  },

  computed: {
    logoFill() {
      // eslint-disable max-len
      return this.lightUi && this.scrollPosition < this.scrollTreshold.color && !this.isNavVisible
        ? { letters: '#FFFFFF', brand: '#FFFFFF' }
        : { letters: '#1D1D1B', brand: '#009EE2' };
    },
  },

  methods: {
    onWindowScroll() {
      this.scrollDirection = this.scrollPosition < window.scrollY ? 'down' : 'up';
      this.scrollPosition = window.scrollY;
    },
    toggleNavList() {
      this.isNavVisible = !this.isNavVisible;
      this.$refs.navList.style.height = this.isNavVisible ? `${this.$refs.navList.scrollHeight}px` : '';
    },
    scrollTo(key) {
      let top = Position.top(this.targets[key].$el);
      console.log(this.targets, key, top);
      // Exception forthe hero intro
      if (key === 'hero') {
        top = window.innerHeight;
      }

      window.scrollTo({ top, behavior: 'smooth' });
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
