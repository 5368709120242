<template>
  <div class="arrow-navigation">
      <div class="arrow-navigation__container">
         <div
          class="btn-square arrow-navigation__control arrow-navigation__control--left"
          :class="{'arrow-navigation__control--disabled': disabledPrev}"
          @click="$emit('prev')"
        >
          <img src="../assets/arrow_left.svg" />
        </div>
        <div
          class="btn-square arrow-navigation__control arrow-navigation__control--right"
          :class="{'arrow-navigation__control--disabled': disabledNext}"
          @click="$emit('next')"
        >
          <img src="../assets/arrow_right.svg" />
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ArrowNavigation',

  props: {
    disabledPrev: Boolean,
    disabledNext: Boolean,
  },
};
</script>
