<template>
  <div
    role="dialog"
    aria-live="polite"
    aria-label="cookieconsent"
    aria-describedby="cookieconsent:desc"
    class="cookie-banner"
    v-if="isVisible"
  >
    <!--googleoff: all-->
    <span id="cookieconsent:desc" class="cc-message">
      <p>
        Busch-Jaeger verwendet Cookies,
        um Ihnen den bestmöglichen Service zu gewährleisten.
        Mit der Nutzung unserer Website erklären Sie sich damit
        einverstanden, dass wir Cookies verwenden. Weitere Informationen
        zu Cookies und unserem Datenschutz finden
        <a
          aria-label="learn more about cookies"
          role="button"
          tabindex="0"
          href="https://www.busch-jaeger.de/datenschutz"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >Sie hier</a>.
      </p>
    </span>
    <div class="cc-compliance">
      <a
        aria-label="dismiss cookie message"
        role="button"
        tabindex="0"
        class="cookie-banner__close-btn"
        @click="hide"
      >
        <span class="mdi mdi-close"></span>
      </a>
    </div>
    <!--googleon: all-->
  </div>
</template>

<script>
export default {
  name: 'CookieBanner',

  data() {
    return {
      isVisible: true,
    };
  },

  mounted() {
    this.isVisible = !localStorage.getItem('cookie-message-seen');
  },

  methods: {
    hide() {
      this.isVisible = false;
      localStorage.setItem('cookie-message-seen', 1);
    },
  },
};
</script>

<style lang="scss">
.cookie-banner {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #4a4a4a;
  z-index: 20;

  color: #fff;
  padding: 20px;
  padding-top: 45px;

  p {
    margin: 0;
  }

  a {
    font-size: inherit;
  }

  a#{&}__close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 30px;
  }
}
</style>
