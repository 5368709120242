<template>
  <picture>
    <source
      v-for="(item, index) in sources"
      :key="index"
      :media="item.media"
      :srcset="item.src"
    >
    <img :src="src">
  </picture>
</template>

<script>
import Breakpoints from '../utils/breakpoints';

export default {
  name: 'ImageResponsive',
  props: {
    src: String,
    variants: Array,
  },
  computed: {
    sources() {
      return this.variants.map((src) => {
        const related = Breakpoints.breakpoints.find((breakpoint) => src.includes(`_${breakpoint.key}`));
        return {
          ...related,
          media: `(min-width: ${related.value}px)`,
          src,
        };
      });
    },
  },
};
</script>

<style lang="scss">
picture {
  object-fit: cover;
}
</style>
