<template>
    <transition name="fade">
      <div
          class="product-highlight__variants"
          v-if="activeIndex === 2 && imageVariants.length > 0"
      >
          <div
            v-for="(variant, index) of productVariantsImages"
            class="product-highlight__variant"
            :class="{'product-highlight__variant--active': productVariantIndex === index}"
            :key="index"
            @click="$emit('variant-click', index)"
          >
            <div class="product-highlight__variant-inner">
              <img :src="variant">
            </div>
          </div>
      </div>
    </transition>
</template>
<script>
export default {
  name: 'ProductHighlightVariants',
  props: {
    activeIndex: {
      type: Number,
      required: true,
    },
    productVariantsImages: {
      type: Array,
      required: true,
    },
    imageVariants: {
      type: Array,
      required: true,
    },
    productVariantIndex: {
      type: Number,
      required: true,
    },
  },
};
</script>
