<template>
  <div id="app">
    <app-header
      :targets="targets"
      :product-stories-count="productStoriesCount"
    />
    <main>
      <hero ref="hero" @mounted="onHeroMounted"/>
      <product-highlight
        ref="productHighlight"
        :intro-position="introPosition"
      />
      <product-stories ref="productStories" />
      <testimonials ref="testimonials" />
      <lead ref="lead" />
      <story ref="story" />
      <jump ref="jump" />
    </main>
    <app-footer/>
    <cookie-banner />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';
import Hero from './components/Hero.vue';
import Testimonials from './components/Testimonials.vue';
import Story from './components/Story.vue';
import Lead from './components/Lead.vue';
import Jump from './components/Jump.vue';
import CookieBanner from './components/CookieBanner.vue';
import ProductStories from './components/ProductStories.vue';
import ProductHighlight from './components/ProductHighlight.vue';

export default {
  name: 'App',

  components: {
    AppHeader,
    AppFooter,
    Hero,
    Testimonials,
    Lead,
    Story,
    Jump,
    CookieBanner,
    ProductStories,
    ProductHighlight,
  },

  data() {
    return {
      introPosition: null,
      targets: null,
    };
  },

  mounted() {
    this.targets = {
      hero: this.$refs.hero,
      productHighlight: this.$refs.productHighlight,
      testimonials: this.$refs.testimonials,
      lead: this.$refs.lead,
      story: this.$refs.story,
      jump: this.$refs.jump,
      productStories: this.$refs.productStories,
    };
  },

  computed: {
    productStoriesCount() {
      return this.$t('productStory.products').length;
    },
  },

  methods: {
    onHeroMounted(position) {
      this.introPosition = position;
    },
  },

};
</script>
